define("additive-mi/translations/de", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "aiButton": {
      "loadingProgress": "Lädt..."
    },
    "analytics": {
      "description": "Jede Interaktion eines Besuchers auf und mit Ihrer Website und Ihren Landingpages stärkt die Bekanntheit Ihres Produkts sowie Ihrer Marke und führt im besten Fall zu direkten oder indirekten Umsätzen in naher oder späterer Zukunft. Wichtig dabei ist, dass jeder Zugriff und jede Interaktion einen Wert hat.<br/>ADDITIVE macht diesen Wert unter \"Wertschöpfung\" als realen Geldwert sichtbar.</br></br>Alle auf Ihrer Website und Ihren Landingpages durchgeführten Interaktionen im gewählten Zeitraum werden analysiert und den entsprechenden Werbekampagnen und/oder Zugriffsquellen zugeordnet. Bei Aufenthaltsanfragen und Online-Buchungen werden die daraus resultierenden Reservierungen und Umsätze ermittelt. Sind Werbekonten verknüpft, werden zudem die Werbeausgaben ermittelt und den Werbekampagnen zugeordnet.",
      "filterButton": "Filtern",
      "title": "Auswertungen"
    },
    "appNames": {
      "1": {
        "name": "ACCOUNT",
        "presentationName": "ADDITIVE+ ACCOUNT"
      },
      "10": {
        "name": "MARKETING DASHBOARD",
        "presentationName": "ADDITIVE+ MARKETING DASHBOARD"
      },
      "100": {
        "name": "CMS",
        "presentationName": "ADDITIVE+ CMS"
      },
      "11": {
        "name": "ANFRAGEN",
        "presentationName": "ADDITIVE+ ANFRAGEN"
      },
      "12": {
        "name": "CRM",
        "presentationName": "ADDITIVE+ CRM"
      },
      "2": {
        "name": "GUTSCHEINE",
        "presentationName": "ADDITIVE+ GUTSCHEINE"
      },
      "4": {
        "name": "INHALTE",
        "presentationName": "ADDITIVE+ INHALTE"
      },
      "5": {
        "name": "MULTIMEDIA",
        "presentationName": "ADDITIVE+ MULTIMEDIA"
      },
      "50": {
        "name": "MARKETING AUTOMATION",
        "presentationName": "ADDITIVE+ MARKETING AUTOMATION"
      },
      "51": {
        "name": "MARKETING INSIGHTS",
        "presentationName": "ADDITIVE+ MARKETING INSIGHTS"
      },
      "6": {
        "name": "MAILER",
        "presentationName": "ADDITIVE+ MAILER"
      },
      "7": {
        "name": "NEWSLETTER",
        "presentationName": "ADDITIVE+ NEWSLETTER"
      },
      "8": {
        "name": "GÄSTE INTRANET",
        "presentationName": "ADDITIVE+ GÄSTE INTRANET"
      },
      "9": {
        "name": "JOURNAL",
        "presentationName": "ADDITIVE+ JOURNAL"
      }
    },
    "auditLog": {
      "deletedUser": "Gelöschter Benutzer",
      "description": "Hier werden die Aktivitäten aller Benutzer Ihrer Organisation innerhalb der App chronologisch aufgelistet.",
      "empty": "Keine Aktivitäten vorhanden",
      "entries": "Einträge",
      "page": "Seite",
      "time": "{time} Uhr",
      "title": "Aktivitätenprotokoll"
    },
    "breadcrumbs": {
      "demo": {
        "views": {
          "routeName": "Views",
          "simple-breadcrumb": {
            "routeName": "simple-breadcrumb"
          }
        }
      },
      "docs": {
        "components": {
          "routeName": "Components",
          "ui-breadcrumbs": {
            "routeName": "UI-Breadcrumbs"
          },
          "ui-button": {
            "routeName": "Buttons"
          },
          "ui-chart": {
            "routeName": "Charts"
          }
        },
        "routeName": "Docs"
      },
      "instance": {
        "campaign-reports": {
          "report": {
            "routeName": "Kampagnen-Report"
          },
          "routeName": "Kampagnen-Reports"
        },
        "dashboard": {
          "routeName": "MARKETING INSIGHTS"
        }
      }
    },
    "components": {
      "AmiAttributionTableButton": {
        "detail": "Details"
      },
      "amiAttributionTableDetailDialog": {
        "chart": {
          "title": "Verteilung auf Monatsbasis"
        }
      },
      "amiFilters": {
        "attributionModes": {
          "label": "Attributionsmethode",
          "placeholder": "Wählen Sie eine Attributionsmethode."
        },
        "conversionTypes": {
          "label": "Conversion-Typen",
          "placeholder": "Wählen Sie einen Conversion-Typ."
        },
        "groupingHierarchy": {
          "label": "Berechnungsreihenfolge",
          "options": {
            "campaign": "Kampagne",
            "medium": "Medium",
            "source": "Quelle"
          }
        },
        "groupings": {
          "label": "Gruppierungen",
          "placeholder": "Gruppierungen hinzufügen"
        },
        "hideableColumns": {
          "label": "Spalten",
          "options": {
            "actionTracking": "Ereignisse",
            "campaignBudgets": "Werbeausgaben",
            "conversionRates": "Umwandlungsraten",
            "conversionTypes": "Conversion-Typen",
            "turnoverPerTypes": "Umsätze pro Conversion-Typ"
          },
          "placeholder": "Spalten hinzufügen"
        },
        "reservationTypes": {
          "label": "Reservierungstypen einbeziehen",
          "name": "Reservierungstypen",
          "options": {
            "cancelled": "Stornierungen",
            "option": "Zukünftige Optionen"
          },
          "placeholder": "Reservierungstypen hinzufügen"
        },
        "sessionBasis": {
          "label": "[BETA] Session Basis",
          "options": {
            "cookieless": "[BETA] Cookieless",
            "cookies": "Cookies"
          },
          "placeholder": "Session Basis hinzufügen"
        },
        "title": "Filter"
      },
      "amiManagedFetch": {
        "error": "Ein unerwarteter Fehler ist aufgetreten.",
        "retry": "Erneut versuchen"
      },
      "amiNotifications": {
        "alertTypes": {
          "guests-upload": "Fehlerhafter Personen-Upload in ADDITIVE+ CRM am: ",
          "reservations-upload": "Fehlerhafter Reservierungs-Upload in ADDITIVE+ CRM am: ",
          "subscriber-upload": "Fehlerhafter Empfänger-Upload am: "
        }
      },
      "amiReports": {
        "errorPage": {
          "error": "Der Kampagnen-Report ist nicht mehr verfügbar."
        },
        "list": {
          "affectedMonths": "Betroffene Monate:",
          "automatedEmpty": "Es sind noch keine automatisierten Kampagnen-Reports vorhanden.",
          "card": {
            "actions": {
              "deleteReport": {
                "description": "Wollen Sie den Kampagnen-Report wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
                "title": "Kampagnen-Report löschen"
              },
              "publishReport": {
                "publish": "Wollen Sie den Kampagnen-Report veröffentlichen?",
                "title": "Kampagnen-Report veröffentlichen",
                "unpublish": "Wollen Sie den Kampagnen-Report wirklich unveröffentlichen?"
              },
              "recalculateReport": {
                "description": "Wollen Sie den Kampagnen-Report neu berechnen?",
                "title": "Kampagnen-Report neu berechnen"
              },
              "revokeShare": {
                "description": "Wenn Sie fortfahren, wird dieser Freigabelink widerrufen. Die Personen, die den Bericht erhalten haben, können dann nicht mehr durch den alten Freigabelink darauf zugreifen.",
                "title": "Achtung!"
              },
              "shareReport": {
                "actions": {
                  "create": "Freigabelink generieren"
                },
                "create": "Erstellen Sie einen neuen Freigabelink, um den Kampagnen-Report mit anderen zu teilen.",
                "description": "Kopieren Sie diesen Link, um den Kampagnen-Report mit anderen zu teilen.",
                "error": "Beim Laden des Freigabelinks ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
                "regenerate": "Beachten Sie, dass der Widerruf oder die Erstellung eines neuen Freigabelinks dazu führt, dass der alte Freigabelink nicht mehr funktioniert und die Personen, die den Bericht erhalten haben, nicht mehr darauf zugreifen können.",
                "title": "Kampagnen-Report teilen"
              },
              "showConfiguration": "Konfiguration anzeigen",
              "slideshow": "Slideshow"
            },
            "nonPublished": "Nicht veröffentlicht",
            "published": "Veröffentlicht"
          },
          "customEmpty": "Es sind noch keine benutzerdefinierte Kampagnen-Reports vorhanden.",
          "error": "Beim Laden der Kampagnen-Reports ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
        },
        "settingsNavdrawer": {
          "defaultTitle": "Standard-Einstellungen",
          "description": "Hier können Sie die Standard-Einstellungen für neue Kampagnen-Reports ändern. Um die Einstellungen für einen bestimmten Kampagnen-Report anzuzeigen, klicken Sie auf \"Konfiguration anzeigen\" in der jeweiligen Vorschau.",
          "labels": {
            "averageReservationRevenue": "Durchschnittlicher Reservierungsumsatz",
            "calculateConversionValuesAsRevenue": "Conversion-Werte von Direktbuchungen als Umsatz berechnen",
            "calculateNewsletterOpenings": "Newsletter-Kampagnen einbeziehen",
            "calculateRoi": "A+ROI berechnen",
            "cancellationsAsReservations": "Stornierungen als Reservierungen",
            "conversionRateRequestsToReservations": "Wandlungsrate Anfragen zu Reservierungen",
            "description": "Performance Zusammenfassung",
            "headerImage": "Headerbild",
            "includeHosts": "Direkte oder unbekannte Einstiege auf diese Hosts berücksichtigen",
            "optionsAsReservations": "Optionen als Reservierungen",
            "presentation": "Präsentation",
            "sessionBasis": "Session Basis",
            "showCharts": "Diagramme anzeigen",
            "showDescriptionSlide": "Performance Zusammenfassung anzeigen",
            "showHistoricalDataCharts": {
              "isNotAllowed": "Diese Einstellung ist nur verfügbar, wenn mindestens zwei Reporting-Monate vorhanden sind.",
              "title": "Monatliche Vergleichstabelle anzeigen"
            },
            "showKpiInfo": "Beschreibung der Kennzahlen anzeigen",
            "showNewsletterMarketingSlide": "Newsletter-Marketing Slide anzeigen",
            "useEmpiricalDataToCalculateRevenue": "Erfahrungswerte für Umsatz-Hochrechnung verwenden",
            "useReservationData": "Reservierungsdaten verwenden"
          },
          "reportTitle": "Report-Einstellungen",
          "values": {
            "cookieless": "Cookieless",
            "cookies": "Cookies",
            "false": "Nein",
            "true": "Ja"
          }
        }
      },
      "amiSettings": {
        "temporalSettingsList": {
          "budgets": {
            "active": "Aktuelles Budget",
            "budgetMissingWarning": "Es wurde noch kein Budget definiert. Bitte denken Sie daran, das Budget zu definieren, damit die Kennzahlen im Kampagnen-Report korrekt berechnet werden..",
            "currentBudgetInfo": "<div>Das aktuelle Budget beträgt <strong>{amount}</strong>.</div>Dieser Wert wird für zukünftige Kampagnen-Reports verwendet, wenn er nicht überschrieben wird.",
            "deleteDialog": {
              "description": "Das Budget wird endgültig gelöscht. Wirklich fortfahren?",
              "title": "Budget löschen"
            },
            "description": "Hier können Sie Budgets pro Monat definieren. Diese werden für die Berechnung der Kennzahlen in den Kampagnen-Reports verwendet.",
            "empty": "Es ist kein Budget vorhanden.",
            "future": "Zukünftige Budgets",
            "past": "Vergangene Budgets"
          },
          "detailDialog": {
            "budgets": {
              "title": "Budget"
            },
            "changeDate": "Änderungsdatum",
            "date": "Datum",
            "dateMonth": "Monat",
            "dateYear": "Jahr",
            "note": "Notiz",
            "percentage": "%",
            "roundingInfo": "Der Wert wird auf 2 Nachkommastellen gerundet",
            "serviceCosts": {
              "title": "Servicegebühren"
            },
            "surcharge": "Zuzüglich Management-Gebühr",
            "value": "Betrag"
          },
          "serviceCosts": {
            "active": "Aktuelle Servicegebühren",
            "currentServiceCostsInfo": "<div>Die aktuellen Servicegebühren betragen <strong>{amount}</strong>.</div>Dieser Wert wird für zukünftige Kampagnen-Reports verwendet, wenn er nicht überschrieben wird.",
            "currentServiceCostsWarning": "Es wurden noch keine Servicegebühren definiert. Bitte denken Sie daran, die Servicegebühren zu definieren, damit die Kennzahlen im Kampagnen-Report korrekt berechnet werden.",
            "deleteDialog": {
              "description": "Die Servicegebühr wird endgültig gelöscht. Wirklich fortfahren?",
              "title": "Servicegebühr löschen"
            },
            "description": "Hier können Sie Servicegebühren pro Monat definieren. Diese werden für die Berechnung der Kennzahlen in den Kampagnen-Reports verwendet.",
            "empty": "Es sind keine Servicegebühren vorhanden.",
            "future": "Zukünftige Servicegebühren",
            "past": "Vergangene Servicegebühren"
          }
        }
      },
      "ui-metric-item": {
        "comparePeriod": "zum Vorjahr",
        "placeholder": "Keine Veränderung zum Vorjahr"
      }
    },
    "conversionAttribution": {
      "description": "Die auf Ihrer Website und Ihren Landingpages erfassten Conversions im gewählten Zeitraum werden analysiert und mittels gewählter Attributionsmethode den \"beteiligten\" Werbekampagnen und/oder Zugriffsquellen zugeordnet.<br/>Bei Aufenthaltsanfragen und Online-Buchungen werden die daraus resultierenden Reservierungen und Umsätze ermittelt und ebenfalls anhand der gewählten Attributionsmethode zugeordnet.",
      "filterButton": "Filtern",
      "title": "Conversion-Attribution"
    },
    "dashboard": {
      "conversions": {
        "bookings": "Online-Buchungen",
        "conversions": "Online Conversions",
        "description": "Die diversen Online Conversions auf Ihrer Website und Ihren Landingpages können anhand des ADDITIVE+ APPS Snippets erfasst werden.",
        "enquiries": "Anfragen",
        "leads": "Leads",
        "newsletterSubscriptions": "Newsletter-Anmeldungen",
        "other": "Andere",
        "title": "Online Conversions",
        "vouchers": "Gutscheine"
      },
      "description": "Im Folgenden erhalten Sie einen Gesamtüberblick über wichtige Kennzahlen zu den im gewählten Zeitraum erzielten Interaktionen, Conversions und Umsätzen auf Ihrer Website und Ihren Landingpages. Beachten Sie, dass hierfür das ADDITIVE+ APPS Snippet implementiert sein muss.",
      "filters": {
        "hosts": {
          "label": "Kanal",
          "placeholder": "Alle"
        }
      },
      "information": {
        "title": "Allgemeine Snippet-Informationen"
      },
      "keyIndicators": {
        "conversions": "Online Conversions",
        "reservations": "Reservierungen",
        "sessions": "Sitzungen",
        "turnover": "Umsatz durch Reservierungen"
      },
      "performance": {
        "avgEnquiryValue": "ø Wert einer Anfrage",
        "avgReservationsTurnover": "ø Umsatz einer Reservierung",
        "avgSessionValue": "ø Wert einer Sitzung",
        "description": "Nachfolgend sehen Sie die Umwandlungsraten von Besuchern zu Anfragenden bis hin zu den Reservierungen sowie der daraus resultierenden durchschnittlichen Geldwerte der einzelnen Aktionen.",
        "enquiryBookingRatio": "Anfragen zu Reservierung",
        "enquiryConversionRateHelpText": "Diese Werte beziehen sich nur auf Reservierungen, welche aus Anfrage-Conversions entstanden sind.",
        "sessionsEnquiryRatio": "Sitzungen zu Anfrage",
        "title": "Performance Kennzahlen"
      },
      "reservations": {
        "description": "Bei der Ermittlung der Reservierungen werden die erfassten Online Conversions mit den Reservierungsdaten des verbundenen PMS abgeglichen und die reservierende Person anhand der bisher getätigten Reservierungen entsprechend als „neu“ oder „wiederkehrend“ eingestuft.",
        "newGuests": "Neue Reservierer",
        "reservations": "Reservierungen",
        "returningGuests": "Wiederkehrende Reservierer",
        "showReservations": "Reservierungen anzeigen",
        "title": "Reservierungen"
      },
      "salutations": {
        "afternoon": "Guten Nachmittag",
        "evening": "Guten Abend",
        "morning": "Guten Morgen"
      },
      "title": "Übersicht",
      "upgradeMessage": {
        "buttonText": "Mehr Informationen",
        "plan": "PRO",
        "text": "Mehr Umsatz, Erfolg und Zeitersparnis dank Automatisierung. Intelligentes Marketing mit der Marketing-Software speziell für Hotels.",
        "title": "MARKETING AUTOMATION"
      }
    },
    "dialogService": {
      "conflictError": {
        "dependencies": {
          "contentTypes": {
            "appIntegrations": "ADDITIVE+ APPS Integrationen",
            "campaigns": "Kampagnen",
            "comments": "Kommentare",
            "landingPages": "Landingpages",
            "leadIntegrations": "Lead-Integrations",
            "pmsIntegrations": "Hotel Software Integrationen",
            "posts": "Beiträge",
            "settings": "Einstellungen",
            "surveys": "Umfragen",
            "thirdPartyTokens": "ADDITIVE+ APPS Schnittstellen",
            "usps": "USPs",
            "widgets": "Widgets"
          },
          "headers": {
            "app": "App",
            "content": "Inhalt",
            "contentType": "Inhaltstyp"
          }
        },
        "description": "Das Objekt kann nicht gelöscht werden, da es von anderen Inhalten verwendet wird. Lösen Sie die Abhängigkeiten bevor Sie das Objekt löschen.",
        "title": "Das Objekt ist in Verwendung"
      },
      "discardChanges": {
        "continueEditing": "Weiterarbeiten",
        "discardAction": "Verwerfen",
        "message": "Sollen ungespeicherte Änderungen verworfen werden?",
        "title": "Achtung!"
      },
      "error": {
        "message": "Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut!",
        "title": "Etwas ist schief gelaufen"
      },
      "noPermission": {
        "message": "Sie haben leider keine Berechtigung um diese Aktion durchzuführen. Wenden Sie sich an Ihren Administrator.",
        "title": "Fehlende Berechtigung"
      }
    },
    "enquiryAttribution": {
      "date": {
        "description": "In nachfolgender Auswertung werden alle auf Ihrer Website und Ihren Landingpages gestellten Anfragen im gewählten Zeitraum analysiert, den entsprechenden Werbekampagnen zugeordnet und die daraus resultierenden Reservierungen ermittelt. Beachten Sie, dass hierfür die Anfragen mittels ADDITIVE+ ANFRAGEN verarbeitet werden müssen.",
        "title": "Attribution nach Anfragedatum"
      },
      "description": "In nachfolgenden Auswertungen werden alle auf Ihrer Website und Ihren Landingpages gestellten Aufenthaltsanfragen analysiert, den entsprechenden Werbekampagnen zugeordnet und die daraus resultierenden Reservierungen ermittelt. Dabei werden die Anfragen aus ADDITIVE+ ANFRAGEN herangezogen.<br><br>Betrachtet werden alle Anfragen, die im Betrachtungszeitraum gestellt wurden.",
      "detail": "Details",
      "navigation": {
        "date": {
          "description": "Betrachtet werden alle Anfragen, die im Betrachtungszeitraum gestellt wurden.",
          "title": "Anfragedatum"
        },
        "stay": {
          "description": "Betrachtet werden alle Anfragen mit Ankunftsdatum im Betrachtungszeitraum.",
          "title": "Ankunftsdatum"
        }
      },
      "stay": {
        "description": "In nachfolgender Auswertung werden alle Anfragen über Ihre Website und Landingpages mit einem Anreisedatum im gewählten Zeitraum analysiert, den entsprechenden Werbekampagnen zugeordnet und die daraus resultierenden Reservierungen ermittelt. Beachten Sie, dass hierfür die Anfragen mittels ADDITIVE+ ANFRAGEN verarbeitet werden müssen.",
        "title": "Attribution nach Aufenthalt"
      },
      "title": "Anfragen-Attribution"
    },
    "global": {
      "actions": {
        "activate": "Aktivieren",
        "add": "Hinzufügen",
        "close": "Schließen",
        "configure": "Konfigurieren",
        "copy": "Kopieren",
        "deactivate": "Deaktivieren",
        "delete": "Löschen",
        "disconnect": "Trennen",
        "dismiss": "Schließen",
        "download": "Herunterladen",
        "filter": "Filtern",
        "generateUrl": "Link neu generieren",
        "hide": "Ausblenden",
        "logout": "Abmelden",
        "ok": "OK",
        "publish": "Veröffentlichen",
        "recalculate": "Neu berechnen",
        "retry": "Erneut versuchen",
        "revokeUrl": "Link widerrufen",
        "save": "Speichern",
        "share": "Teilen",
        "show": "Anzeigen",
        "switch": "Ändern",
        "unpublish": "Unveröffentlichen",
        "upgrade": "Upgraden"
      },
      "dates": {
        "days": "{value, plural, =1 {Tag} other {Tage}}",
        "hours": "{value, plural, =1 {Stunde} other {Stunden}}",
        "minutes": "{value, plural, =1 {Minute} other {Minuten}}",
        "months": "{value, plural, =1 {Monat} other {Monate}}",
        "seconds": "{value, plural, =1 {Sekunde} other {Sekunden}}",
        "years": "{value, plural, =1 {Jahr} other {Jahre}}"
      },
      "empty": "Keine Daten vorhanden",
      "errors": {
        "email": "Ungültige E-Mail-Adresse",
        "invalidFormat": "Ungültiges Format",
        "positiveFloatOrZero": "Wert muss positiv mit max. 2 Kommastellen oder 0 sein",
        "positiveInteger": "Wert muss eine positive Ganzzahl sein",
        "positiveNumber": "Wert muss eine positive Zahl sein",
        "rate": "Wert muss dem Format 10:1 entsprechen.",
        "required": "Wert darf nicht leer sein",
        "requiredPositiveInteger": "Wert darf nicht leer sein und muss eine positive Ganzzahl sein",
        "requiredRate": "Wert darf nicht leer sein und muss dem Format 10:1 entsprechen.",
        "title": "Ein Fehler ist aufgetreten",
        "tooManyKpis": "Es dürfen maximal 6 Kennzahlen pro Spalte aktiv sein."
      },
      "languages": {
        "de": "Deutsch",
        "en": "Englisch",
        "fr": "Französisch",
        "it": "Italienisch",
        "nl": "Niederländisch"
      },
      "properties": {
        "image": "Bild",
        "subject": "Betreff",
        "text": "Text"
      },
      "states": {
        "active": "Aktiv",
        "inactive": "Nicht aktiv",
        "loading": "Lädt..."
      },
      "toast": {
        "error": {
          "savedChanges": "Ein unerwarteter Fehler ist aufgetreten"
        },
        "success": {
          "savedChanges": "Aktion war erfolgreich"
        },
        "update": {
          "actionLabel": "Neu laden",
          "description": "Ungespeicherte Änderungen gehen verloren.",
          "title": "Update verfügbar"
        }
      },
      "warnings": {
        "multimedia": {
          "image": "Es können nur Bilder ausgewählt werden."
        }
      }
    },
    "languageSelect": {
      "add": "Sprache hinzufügen",
      "ar": "Arabisch",
      "de": "Deutsch",
      "en": "Englisch",
      "fr": "Französisch",
      "it": "Italienisch",
      "nl": "Niederländisch",
      "pl": "Polnisch",
      "remove": "Sprache entfernen",
      "removeDescription": "Soll die Sprache {language} wirklich entfernt werden?",
      "ru": "Russisch"
    },
    "mmAttributesDialog": {
      "alt": "Alt-Attribut",
      "dialogTitle": "Bild Attribute",
      "helpText": "Der Title-Tag eines Bildes ist der Text, der angezeigt wird, wenn man mit dem Mauszeiger auf dem Bild stehenbleibt. Das Alt-Attribut wird von Suchmaschinen verwendet um den Inhalt des Bildes zuzuordnen oder kann auch angezeigt werden, falls das Bild nicht geladen werden kann.",
      "save": "Speichern",
      "title": "Titel"
    },
    "mmBulkDownload": {
      "confirm": {
        "description": "Wollen Sie diesen Ordner herunterladen? Der Download Link wird Ihnen per E-mail zugesendet.",
        "title": "Ordner herunterladen"
      },
      "download": "Herunterladen",
      "error": {
        "description": "Der Download des ausgewählten Mediums wird nicht unterstützt.",
        "title": "Achtung"
      },
      "success": {
        "description": "Der Download Link wird Ihnen per E-mail zugesendet.",
        "title": "Download erfolgreich gestartet"
      }
    },
    "mmCropperView": {
      "save": "Speichern"
    },
    "mmExternalSearch": {
      "duration": {
        "hours": "{count, plural, =1 {# Stunde} other {# Stunden}}",
        "minutes": "{count, plural, =1 {# Minute} other {# Minuten}}",
        "seconds": "{count, plural, =1 {# Sekunde} other {# Sekunden}}"
      },
      "insertSearchParam": "Geben Sie einen Suchbegriff ein",
      "noResults": "Keine Suchergebnisse gefunden",
      "provider": {
        "unsplash": {
          "count": "{count, plural, =1 {1 Bild} other {# Bilder}}",
          "description": "Geben Sie hier einen Suchbegriff ein, um nach Unsplash Bildern zu suchen.",
          "name": "Unsplash",
          "save": "Bilder hinzufügen",
          "title": "Unsplash Bild hinzufügen"
        },
        "youtube": {
          "count": "{count, plural, =1 {1 Video} other {# Videos}}",
          "description": "Geben Sie hier einen Suchbegriff ein, um das gewünschte YouTube Video hinzuzufügen oder geben Sie direkt die jeweilige URL ein.",
          "name": "YouTube",
          "save": "Video hinzufügen",
          "title": "Youtube-Video hinzufügen"
        }
      }
    },
    "mmImageTransforms": {
      "brightness": "Helligkeit",
      "contrast": "Kontrast",
      "editDialog": {
        "attributes": {
          "description": "Beschreibungs- und Alternativtext des Bildes festlegen",
          "title": "Bild Attribute setzen"
        },
        "imageTransform": {
          "description": "Damit können Sie das Bild bearbeiten",
          "title": "Bild bearbeiten"
        }
      },
      "imageLoadError": "Beim Laden des Bildes ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.",
      "saturation": "Sättigung",
      "setFocusPoint": "Fokus-Point setzen",
      "title": "Bild bearbeiten"
    },
    "mmMediumItem": {
      "attribution": {
        "unsplash": "Photo by &nbsp;<a target=\"_blank\" rel=\"noopener\" href=\"{authorUrl}\" class=\"white\">{author}</a>&nbsp; on &nbsp;<a target=\"_blank\" rel=\"noopener\" href=\"{url}\" class=\"white\">Unsplash</a>",
        "youtube": "{title} on <u>Youtube</u>"
      },
      "download": "Herunterladen",
      "error": "Die Datei konnte nicht geladen werden.",
      "more": "Mehr"
    },
    "mmResourceGallery": {
      "isImageAddable": "Bild hinzufügen",
      "isImageOrVideoAddable": "Bild oder Video hinzufügen",
      "isVideoAddable": "Video hinzufügen"
    },
    "multimediaEngine": {
      "actions": {
        "add": "Hinzufügen",
        "bulkDelete": "Löschen",
        "bulkDownload": "Herunterladen",
        "delete": "Löschen",
        "move": "Hierher verschieben",
        "remove": "Entfernen",
        "rename": "Umbenennen",
        "retry": "Nochmal versuchen",
        "save": "Speichern"
      },
      "dialogs": {
        "confirmDeleteFolder": {
          "description": "Sind Sie sicher, dass Sie diesen Ordner und dessen Inhalt unwiderruflich löschen wollen?",
          "title": "Ordner löschen"
        },
        "confirmDownloadFolder": {
          "description": "Wollen Sie diesen Ordner herunterladen? Der Download Link wird Ihnen per E-mail zugesendet.",
          "title": "Ordner herunterladen"
        },
        "confirmRemoveFolder": {
          "description": "Sind Sie sicher, dass Sie diesen geteilten Ordner und dessen Inhalt entfernen wollen?",
          "title": "Geteilten Ordner entfernen"
        },
        "deleteFolderIncomplete": {
          "description": "Der Ordner konnte nicht vollständig gelöscht werden, da einige Dateien aktuell verwendet werden. Insgesamt {count, plural, =1 {wurde # Datei} other {wurden # Dateien}} gelöscht.",
          "title": "Ordner konnte nicht gelöscht werden"
        },
        "removeFolderError": {
          "description": "Der geteilte Ordner konnte nicht entfernt werden, da einige Dateien aktuell verwendet werden.",
          "title": "Ordner konnte nicht entfernt werden"
        },
        "youtubeDownloadError": {
          "description": "Der Download des ausgewählten Mediums wird nicht unterstützt.",
          "title": "Achtung"
        }
      },
      "errors": {
        "required": "Wert darf nicht leer sein"
      },
      "folderTree": {
        "root": "Multimedia"
      },
      "folderView": {
        "bulkDelete": {
          "confirmDialog": {
            "description": "Sind Sie sicher, dass Sie {count, plural, =1 {dieses Medium} other {diese Medien}} unwiderruflich löschen wollen?",
            "title": "{count, plural, =1 {Medium} other {Medien}} löschen"
          },
          "conflict": {
            "description": "Ein oder mehrere Medien können nicht gelöscht werden, da sie zurzeit in Inhalten verwendet werden. Um diese Medien löschen zu können müssen Sie diese zuerst von allen Inhalten entfernen.",
            "title": "Nicht alle Medien sind löschbar"
          },
          "disabled": "Geteilte Ordner und Dateien können nicht gelöscht werden.",
          "error": {
            "description": "Die ausgewählten Medien können nicht gelöscht werden, da sie zurzeit in Inhalten verwendet werden. Um diese Medien löschen zu können müssen Sie diese zuerst von allen Inhalten entfernen.",
            "title": "Medien sind nicht löschbar"
          }
        },
        "bulkDownload": {
          "confirmDialog": {
            "description": "Sind Sie sicher, dass Sie {count, plural, =1 {dieses Medium} other {diese Medien}} herunterladen wollen? Der Download Link wird Ihnen per E-mail zugesendet.",
            "title": "{count, plural, =1 {Medium} other {Medien}} herunterladen"
          },
          "disabled": "Geteilte Ordner und Dateien können nicht heruntergeladen werden."
        },
        "bulkMove": {
          "disabledParentFolder": "Ordner können nicht in sich selbst verschoben werden.",
          "disabledSharedFolder": "Innerhalb geteilter Ordner können Ordner und Dateien nicht verschoben werden.",
          "disabledSharedSelection": "Geteilte Ordner und Dateien können nicht verschoben werden."
        },
        "delete": {
          "confirmDeleteMedium": {
            "description": "Sind Sie sicher, dass Sie dieses Medium unwiderruflich löschen wollen?",
            "title": "Medium löschen"
          },
          "conflict": {
            "description": "Das Medium kann nicht gelöscht werden, da es zurzeit in Inhalten verwendet wird. Um dieses Medium löschen zu können müssen Sie es zuerst von allen Inhalten entfernen.",
            "title": "Medium ist in Verwendung"
          }
        },
        "folder": "Ordner",
        "media": "Dateien",
        "new": {
          "addSharedFolder": {
            "title": "Geteilten Ordner hinzufügen"
          },
          "externalSearch": {
            "title": "Externe Medien"
          },
          "folder": {
            "title": "Ordner erstellen"
          },
          "title": "Hinzufügen",
          "upload": {
            "title": "Datei hochladen"
          }
        },
        "sharedFolder": "Geteilte Ordner",
        "sharedMedia": "Geteilte Dateien",
        "uploading": {
          "description": "Während ein Hochladevorgang läuft kann nichts weiteres hochgeladen werden.",
          "title": "Hochladevorgang läuft."
        }
      },
      "mmAddSharedFolderDialog": {
        "errors": {
          "add": "Beim Hinzufügen der geteilten Ordner ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
          "fetch": "Beim Laden der geteilten Ordner ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
        },
        "title": "Geteilten Ordner hinzufügen"
      },
      "mmFileUpload": {
        "error": {
          "title": "Fehler beim Hochladen"
        },
        "maxConcurrentFilesUploaded": {
          "description": "Sie können nur maximal {count} Dateien in einem Hochladevorgang hochladen. Sie können die Restlichen nach dem der aktuelle Hochladevorgang beendet ist hochladen.",
          "title": "Limit für gleichzeitiges Hochladen erreicht"
        },
        "maxSizeDialog": {
          "description": "Eine oder mehrere Dateien welche sie hochladen möchten überschreiten die zulässige Maximalgröße von 10MB, diese werden nicht hochgeladen.",
          "title": "Überschreitung der Maximalgröße"
        }
      },
      "mmFileUploadProgress": {
        "aborted": {
          "title": "Hochladen abgebrochen"
        },
        "errorDialog": {
          "description": "{failed} von {count} konnten nicht hochgeladen werden, da sie entweder nicht unterstützt werden oder ein unerwarteter Fehler aufgetreten ist.",
          "title": "Uploadfehler"
        },
        "progress": {
          "description": "Dateien werden hochgeladen",
          "title": "Hochladen"
        },
        "success": {
          "title": "Hochladen erfolgreich"
        }
      },
      "mmFilter": {
        "mr": "Größer als",
        "noMatches": "Ihre Filterung ergab keine Treffer",
        "searchPlaceholder": "Suchen Sie z.B. nach \"Wellness\"",
        "type": {
          "title": "Dateityp",
          "types": {
            "file": "Datei",
            "image": "Bild",
            "video": "Video"
          }
        }
      },
      "mmFolderCreateEdit": {
        "name": "Name",
        "title": {
          "folder": {
            "create": "Ordner erstellen",
            "edit": "Ordner umbenennen"
          },
          "sharedFolder": {
            "create": "Geteilten Ordner erstellen",
            "edit": "Geteilten Ordner umbenennen"
          }
        }
      },
      "toasts": {
        "bulkDownloadSuccess": {
          "description": "Der Download Link wird Ihnen per E-mail zugesendet",
          "title": "Download erfolgreich gestartet"
        },
        "folderDeleteSuccess": {
          "description": "Der Ordner wurde erfolgreich gelöscht",
          "title": "Ordner gelöscht"
        },
        "success": {
          "title": "Aktion erfolgreich durchgeführt"
        }
      },
      "warnings": {
        "maxCount": "Es {count, plural, =1 {darf maximal # Element} other {dürfen maximal # Elemente}} ausgewählt werden.",
        "recommendedSize": {
          "action": "Trotzdem hinzufügen",
          "message": "In Ihrer Auswahl befinden sich Dateien, welche nicht der empfohlenen Mindestgröße entsprechen.",
          "title": "Achtung"
        }
      }
    },
    "onboarding": {
      "1": {
        "description": "Laufende Erfolgsmessung und Ergebnisanalyse ist essentiell für eine gelungene Kampagnenplanung und -steuerung. ADDITIVE+ MARKETING INSIGHTS versorgt Sie mit den notwendigen Daten.",
        "title": "Erfolgsmessung im Marketing"
      },
      "2": {
        "description": "Die Datenanbindung an Ihre Hotelsoftware ermöglicht die Zuordnung der erzielten Conversions der Werbekanäle zu den realen Reservierungen und Umsätzen. Die Ergebnisse werden in übersichtlichen Auswertungen aufbereitet.",
        "title": "Attribution"
      },
      "3": {
        "description": "",
        "title": "Kanäle Auswerten"
      }
    },
    "pageNotFound": {
      "button": "Zur Startseite",
      "content": "Leider konnte die gesuchte Seite nicht gefunden werden! <br>Entweder ist die URL nicht korrekt oder die Seite wurde entfernt bzw. umbenannt.",
      "title": "Seite wurde nicht gefunden!"
    },
    "reports": {
      "create": {
        "error": {
          "endBeforeStart": "Darf nicht vor Startdatum sein",
          "endInFuture": "Datum muss vergangen sein",
          "nameRequired": "Name darf nicht leer sein",
          "title": "Fehler"
        },
        "name": "Name",
        "title": "Benutzerdefinierten Kampagnen-Report erstellen"
      },
      "description": "Für ein zielgerichtetes und performantes Marketing sind kontinuierliche Auswertungen und Anpassungen an den Kampagnen nötig. Dafür tauchen unsere Experten tief in die detaillierten Auswertungstools von ADDITIVE sowie Analyse-Tools der Werbeplattformen ein.</br></br>Um schnell und leicht verständlich einen Gesamtüberblick zu erhalten, zeigen folgende Reports fortlaufend mit den wichtigsten Kennzahlen, welche Ergebnisse die Marketingmaßnahmen insgesamt erzielen.",
      "download": {
        "toasts": {
          "error": "Das Herunterladen des PDF ist fehlgeschlagen",
          "loading": {
            "description": "Das PDF wird generiert und in Kürze heruntergeladen.",
            "title": "PDF wird heruntergeladen"
          },
          "success": "Das PDF wurde erforlgreich heruntergeladen"
        }
      },
      "edit": {
        "addKpi": "Benutzerdefinierten Wert hinzufügen",
        "attributionSettings": "Attributions-Einstellungen",
        "calculation": "Berechnung",
        "columns": {
          "firstColumn": "Spalte 2",
          "secondColumn": "Spalte 3",
          "thirdColumn": "Spalte 4"
        },
        "configuration": "Konfiguration",
        "descriptionNotice": {
          "description": "Durch die Neuberechnung des Berichts könnte die Performance Zusammenfassung möglicherweise nicht mehr aktuell sein. Es wird empfohlen, die Performance Zusammenfassung zu überprüfen.",
          "title": "Neuberechnung war erfolgreich"
        },
        "display": "Anzeige",
        "kpiTypes": {
          "currency": "Geldwert",
          "number": "Anzahl",
          "percentage": "Prozentsatz",
          "ratio": "Verhältnis (x:1)"
        },
        "labels": {
          "configuration": {
            "averageReservationRevenue": "Durchschnittlicher Reservierungsumsatz",
            "budget": "Budget",
            "calculateConversionValuesAsRevenue": "Conversion-Werte von Direktbuchungen als Umsatz berechnen",
            "calculateNewsletterOpenings": "Newsletter-Kampagnen einbeziehen",
            "calculateRoi": "A+ROI berechnen",
            "cancellationsAsReservations": "Stornierungen als Reservierungen",
            "conversionRateRequestsToReservations": "Wandlungsrate Anfragen zu Reservierungen",
            "dateRangeDataEmpty": "Ein oder mehrere Monate der ausgewählten Zeitspanne enthalten keine Daten. Bitte passen Sie das Datum an.",
            "dateRangeServiceCostsEmpty": "Sie haben noch kein Budget im Zusammenhang mit den oben ausgewählten Daten definiert. Bitte denken Sie daran, das monatliche Budget hinzuzufügen.",
            "endDate": "Enddatum",
            "includeHosts": {
              "description": "Sitzungen, Conversions und attribuierte Reservierungen von Besuchern, die den Host mittels Direkteinstieg und unbekannten Kampagnen besuchen, werden miteinbezogen.",
              "title": "Direkte oder unbekannte Einstiege auf diese Hosts berücksichtigen"
            },
            "month": "Monat",
            "monthCount": {
              "budget": "Gesamtbudget über {count} {count, plural, =1 {Monat} other {Monate}}",
              "serviceCost": "Gesamte Servicegebühren über {count} {count, plural, =1 {Monat} other {Monate}}"
            },
            "optionsAsReservations": "Optionen als Reservierungen",
            "serviceCost": "Servicegebühr",
            "sessionBasis": {
              "cookieless": "Cookieless",
              "cookies": "Cookies",
              "title": "Session Basis"
            },
            "showCharts": "Diagramme anzeigen",
            "showDescriptionSlide": "Performance Zusammenfassung anzeigen",
            "showHistoricalDataCharts": {
              "isNotAllowed": "Diese Einstellung ist nur verfügbar, wenn mindestens zwei Reporting-Monate vorhanden sind.",
              "isReadOnly": "Diese Konfiguration wird für Kampagnen-Reports mit mehreren Monaten nicht unterstützt.",
              "title": "Monatliche Vergleichstabelle anzeigen"
            },
            "showKpiInfo": "Beschreibung der Kennzahlen anzeigen",
            "showNewsletterMarketingSlide": {
              "isReadOnly": "Diese Konfiguration wird nicht unterstützt wenn die Konfiguration \"Newsletter-Kampagnen einbeziehen\" deaktiviert ist.",
              "title": "Newsletter-Marketing Slide anzeigen"
            },
            "startDate": "Startdatum",
            "useEmpiricalDataToCalculateRevenue": "Erfahrungswerte für Umsatz-Hochrechnung verwenden",
            "useReservationData": {
              "description": "Ist diese Einstellung aktiv, werden Reservierungsdaten als Kennzahlen verwendet (neue und wiederkehrende Reservierungen).",
              "title": "Reservierungsdaten verwenden"
            },
            "year": "Jahr"
          },
          "description": {
            "label": "Performance Zusammenfassung",
            "recommendedWarning": "Wert überschreitet die empfohlene Länge von {recommendedLength} Zeichen."
          },
          "headerImage": "Headerbild",
          "kpis": {
            "label": "Name",
            "type": "Kennzahl-Typ",
            "value": "Wert"
          },
          "roi": {
            "description": "Beschreibung"
          },
          "title": "Titel"
        },
        "mainColumn": "Hauptspalte",
        "originalValue": "Originalwert",
        "roi": "A+ROI",
        "saveDialog": {
          "checkboxLabel": "Aktuelles Layout speichern",
          "recalculationRequiredDescription": "<b>Achtung!</b><br>Wenn Sie den Report speichern, wird der Report automatisch neu berechnet. Möchten Sie fortfahren?",
          "saveLayoutDescription": "Sie sind dabei, den Bericht zu speichern.<br>Möchten Sie das aktuelle Layout (Sichtbarkeit/Reihenfolge) der KPIs als Standard für zukünftige Berichte dieses Typs festlegen?",
          "title": "Report speichern"
        },
        "slides": "Slides",
        "totalValueAdded": "Gesamtwertschöpfung",
        "useReservationDataDialog": {
          "description": "Ist diese Einstellung aktiv, wird das Kennzahlen-Layout verändert und es werden die Kennzahlen \"Reservierungen\", \"Neue Reservierungen\" und \"Wiederkehrende Reservierungen\" verwendet. Außerdem werden alle benutzerdefinierten Kennzahlen ausgeblendet. Möchten Sie fortfahren?",
          "title": "Reservierungsdaten verwenden"
        }
      },
      "notices": {
        "errors": {
          "budget": {
            "action": "Budget hinzufügen"
          },
          "report_generation_day_setting": {
            "action": "Zu den Einstellungen"
          },
          "service-costs": {
            "action": "Servicegebühren hinzufügen"
          }
        }
      },
      "tabs": {
        "automated": "Automatisiert",
        "custom": "Personalisiert"
      },
      "title": "Kampagnen-Reports"
    },
    "routes": {
      "analytics": {
        "title": "Auswertungen"
      },
      "auditlog": {
        "title": "Aktivitätenprotokoll"
      },
      "conversionAttribution": {
        "title": "Conversion-Attribution"
      },
      "dashboard": {
        "title": "Übersicht"
      },
      "enquiryAttribution": {
        "title": "Anfragen-Attribution"
      },
      "reports": {
        "title": "Kampagnen-Reports"
      },
      "settings": {
        "budget": "Budget",
        "general": "Allgemein",
        "reports": "Kampagnen-Reports",
        "serviceCosts": "Servicegebühren",
        "snippet": "A+ APPS Snippet",
        "title": "Einstellungen"
      },
      "stayAttribution": {
        "title": "Aufenthalts-Attribution"
      }
    },
    "select": {
      "empty": "Keine Optionen gefunden",
      "emptyOption": "Keine Auswahl",
      "noResults": "Keine Ergebnisse gefunden",
      "searchPlaceholder": "Suche"
    },
    "selectContainer": {
      "items": "{count, plural, =1 {# Element} other {# Elemente}} ausgewählt"
    },
    "settings": {
      "analytics": {
        "label": "ADDITIVE+ ANALYTICS"
      },
      "attributions": {
        "averageReservationRevenue": {
          "description": {
            "CHF": "Betrag in Schweizer Franken",
            "EUR": "Betrag in Euro",
            "USD": "Betrag in Dollar"
          },
          "title": "Durchschnittlicher Reservierungsumsatz"
        },
        "calculateConversions": {
          "modalDescription": "Wenn diese Einstellung aktiv ist, werden die mit dem ADDITIVE+ APPS Snippet erfassten Online-Buchungen in der Buchungsstrecke als Reservierungen betrachtet und die erfassten Buchungsumsätze zu den Reservierungsumsätzen addiert.<br><br><div><span class=\"font-medium\">Wichtig!</span> Dies führt dazu, dass auch Buchungen, aus denen keine realen Reservierungen entstanden sind (z.B. durch Stornierung) trotzdem gezählt werden.</div><br/><i>Diese Funktion sollte in aller Regel nur aktiviert werden, wenn keine Attribution von Online-Buchungen zu PMS-Reservierungen möglich ist (z.B. wenn keine PMS-Daten im ADDITIVE+ CRM zur Verfügung stehen).</i>",
          "modalTitle": "Wichtiger Hinweis!",
          "title": "Conversion-Werte von Direktbuchungen als Umsatz berechnen"
        },
        "conversionRate": {
          "description": "Bruchzahl, getrennt durch einen Doppelpunkt (z.B. 10:1)",
          "title": "Wandlungsrate Anfragen zu Reservierungen"
        },
        "includeHosts": {
          "description": "Sitzungen, Conversions und attribuierte Reservierungen von Besuchern, die den Host mittels Direkteinstieg und unbekannten Kampagnen besuchen, werden miteinbezogen.",
          "title": "Direkte oder unbekannte Einstiege auf diese Hosts berücksichtigen"
        },
        "label": "Attributionen",
        "useEmpiricalData": {
          "modalDescription": "Wenn diese Einstellung aktiv ist, werden die Reservierungsdaten aus ADDITIVE+ CRM vollständig ignoriert. Stattdessen werden folgende Werte verwendet, um zu berechnen, wieviele Reservierungen und welcher Reservierungsumsatz aus den Anfragen entstanden sein sollte:<ul><li>Wandlungsrate Anfragen zu Reservierungen</li><li>Durchschnittlicher Reservierungsumsatz</li></ul><br/><div><span class=\"font-medium\">Wichtig!</span> Die ermittelten Werte spiegeln nicht die Realsituation wieder und dienen lediglich als Näherungswerte. Die beiden Berechnungswerte müssen daher sehr sorgfältig und für jeden Kunden individuell ermittelt werden.</div><br/><i>Diese Funktion sollte in aller Regel nur aktiviert werden, wenn keine Attribution von Online-Buchungen zu PMS-Reservierungen möglich ist (z.B. wenn keine PMS-Daten im ADDITIVE+ CRM zur Verfügung stehen).</i>",
          "modalTitle": "Wichtiger Hinweis!",
          "title": "Erfahrungswerte für Umsatz-Hochrechnung verwenden"
        }
      },
      "error": "Beim Laden der Einstellungen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      "facebookAds": {
        "adAccount": {
          "input": "Ad-Accounts",
          "label": "Facebook Ad-Accounts"
        },
        "enableFacebookServerSideTracking": "Facebook Server-Side Tracking aktivieren",
        "facebook": "Facebook",
        "facebookInstagram": {
          "input": {
            "adAccount": {
              "infoMessage": "Diese Auswahl beeinflusst, welche Meta-Pixel zur Auswahl stehen.",
              "label": "Ad-Account"
            },
            "adAccounts": {
              "label": "Ad-Accounts für Werbeausgaben-Import"
            },
            "businessManager": {
              "infoMessage": "Diese Auswahl beeinflusst Seite, Instagram-Account und Ad-Accounts.",
              "label": "Business-Manager"
            },
            "facebookPage": {
              "label": "Facebook-Seite"
            },
            "facebookPixel": {
              "infoMessage": "Die zur Auswahl stehenden Meta-Pixel des ausgewählten Ad-Accounts.",
              "label": "Meta-Pixel"
            },
            "instagram": {
              "infoMessage": "Diese Auswahl is optional.",
              "label": "Instagram-Account"
            }
          },
          "label": "Facebook und Instagram Account"
        },
        "facebookOAuth": {
          "connect": "Verbinden",
          "connectedPopover": "Nutzer {user} verbunden",
          "disconnect": "Verbindung trennen",
          "disconnectDialog": {
            "description": "Soll die Verbindung zu Ihrem Facebook Account wirklich getrennt werden?",
            "title": "Verbindung trennen"
          },
          "disconnectedPopover": "Nicht verbunden",
          "label": "Facebook",
          "loginAbortDialog": {
            "description": "Sind Sie sicher, dass Sie die Einstellungen verwerfen wollen? Dadurch wird Ihr Account nicht mit Facebook verbunden.",
            "title": "Einstellungen verwerfen"
          },
          "missingPermissionsDialog": {
            "error": "Es wurden nicht alle benötigten Berechtigungen aktiviert.",
            "title": "Berechtigungen fehlen"
          },
          "reconnect": "Neu verbinden"
        },
        "importFacebookAdSpends": "Facebook Werbeausgaben importieren",
        "label": "Social Media",
        "page": {
          "input": "Seite",
          "label": "Facebook Seite"
        }
      },
      "googleAds": {
        "adAccount": {
          "input": "Ad-Accounts",
          "label": "Google Ads-Kontos"
        },
        "adAccounts": {
          "label": "Ad-Accounts für Werbeausgaben-Import"
        },
        "enableGoogleServerSideTracking": "Google Server-Side Tracking aktivieren",
        "importAdwordsAdspends": "Google Ads Werbeausgaben importieren",
        "label": "Google",
        "loginAbortDialog": {
          "description": "Sind Sie sicher, dass Sie die Einstellungen verwerfen wollen? Dadurch wird Ihr Account nicht mit Google verbunden.",
          "title": "Einstellungen verwerfen"
        },
        "managerAccount": {
          "label": "Manager Account"
        }
      },
      "googleAnalytics": {
        "account": {
          "dialog": {
            "content": "<b>Account:</b> {account}<br><b>Domain:</b> {domain}<br><b>View:</b> {view}<br>",
            "logout": "Konto trennen",
            "title": "Google Analytics Konto"
          },
          "label": "Google Analytics Konto",
          "properties": {
            "googleAnalyticsAccountId": "Google Analytics Account ID",
            "googleAnalyticsDatasetId": "Google Analytics DataSet ID",
            "googleAnalyticsPropertyId": "Google Analytics Property ID"
          }
        },
        "exportFacebookAdSpends": "Facebook Werbeausgaben zu Google Analytics exportieren",
        "label": "Google Analytics",
        "measurementProtocol": {
          "label": "Google Analytics Measurement Protocol"
        },
        "property": {
          "label": "Google Analytics Property"
        },
        "webStream": {
          "label": "Google Analytics Web Stream"
        }
      },
      "oAuth": {
        "connect": "Verbinden",
        "connectedPopover": "Nutzer {user} verbunden",
        "disconnect": "Verbindung trennen",
        "disconnectDialog": {
          "description": "Soll die Verbindung zu Ihrem Account wirklich getrennt werden?",
          "title": "Verbindung trennen"
        },
        "disconnectedPopover": "Nicht verbunden",
        "facebookConnect": {
          "description": "Mit eigenem Facebook-Account verbinden",
          "title": "Mit Facebook-Account verbinden"
        },
        "reconnect": "Neu verbinden",
        "systemUser": {
          "description": "Verwendet den Standard ADDITIVE+ System-User",
          "title": "Als System-User verbinden"
        }
      },
      "reports": {
        "emailReceivers": {
          "description": "Die Kampagnen-Reports werden an die folgenden E-Mail-Adressen gesendet. Beachten Sie, dass nur Nutzer der Organisation Zugang zum jeweiligen Kampagnen-Report haben.",
          "label": "E-Mail-Empfänger der Kampagnen-Reports"
        },
        "generationDay": {
          "description": "Der Tag im Monat, an dem die automatisierten Auswertungen für den jeweiligen Monat generiert werden.",
          "error": "Wert muss zwischen 1 und 28 liegen",
          "inputLabel": "Monatstag",
          "label": "Monatstag für automatisierte Auswertungen"
        }
      },
      "title": "Einstellungen",
      "tracking": {
        "code": "ADDITIVE+ APPS Snippet",
        "configuration": {
          "cookieLevel": {
            "description": "Welche Cookies darf das ADDITIVE+ APPS Snippet im Browser des Seitenbesuchers setzen?<br> Detaillierte Informationen zu den Cookies finden Sie <a class=\"blue font-medium\" href=\"{url}\" target=\"_blank\" rel=\"noopener noreferrer\">hier</a>.",
            "options": {
              "all": "Alle Cookies erlauben",
              "essential": "Nur notwendige Cookies erlauben",
              "none": "Keine Cookies erlauben"
            },
            "title": "Cookie Allow-Level"
          },
          "sdkVersion": "SDK Version",
          "title": "Konfiguration",
          "tracking": {
            "description": "Hier bestimmen Sie, ob das ADDITIVE+ APPS Snippet Besuchersitzungen, Seitenaufrufe und Conversions aufzeichnen soll. Dies ist die Basis für die Erfolgsmessung der Kampagnen.",
            "title": "Sitzungen und Conversions aufzeichnen",
            "warning": "ACHTUNG: Wenn Sie die Aufzeichnung deaktivieren, ist keine Erfolgsauswertung der Kampagnen mehr möglich!"
          }
        },
        "copySuccess": "In die Zwischenablage kopiert",
        "crossDomainTracking": {
          "description": "Zugriffe eines Besuchers auf verschiedene Domains innerhalb einer Sitzung sollen gruppiert werden, um alle Aktionen zur korrekten Einstiegskampagne attribuieren zu können. Dies ist v.a. dann sinnvoll, wenn Besucher für die Conversion auf eine externe Domain geführt werden müssen. Diese Einstellung ermöglicht es Ihnen, mehrere Domains zu definieren, die als Teil einer einzigen Sitzung betrachtet werden sollen.",
          "header": "Hosts für \"Cross-Domain\"-Gruppierung",
          "label": "\"Cross-Domain\"-Sitzungen"
        },
        "downloadManual": "Anleitung herunterladen",
        "excludedIpAddresses": {
          "infoMessage": "Mit Komma getrennte IP-Adressen",
          "label": "Blacklist (Ausgeschlossene IP-Adressen)"
        },
        "ignoredTrackingHosts": {
          "infoMessage": "Mit Komma getrennte Host-URLs",
          "label": "Blacklist (Ausgeschlossene Hosts)"
        },
        "label": "Einbaucode"
      }
    },
    "stayAttribution": {
      "description": "In nachfolgenden Auswertungen werden alle auf Ihrer Website und Ihren Landingpages gestellten Aufenthaltsanfragen analysiert, den entsprechenden Werbekampagnen zugeordnet und die daraus resultierenden Reservierungen ermittelt. Dabei werden die Anfragen aus ADDITIVE+ ANFRAGEN herangezogen.<br><br>Betrachtet werden alle Anfragen mit einem Anreisedatum im Betrachtungszeitraum.",
      "navigation": {
        "arrival": {
          "description": "Betrachtet werden alle Aufenthalts-Conversions mit Ankunftsdatum im Betrachtungszeitraum.",
          "title": "Ankunftsdatum"
        },
        "enquiry": {
          "description": "Betrachtet werden alle Aufenthalts-Conversions, die im Betrachtungszeitraum gestellt wurden.",
          "title": "Anfragedatum"
        }
      },
      "title": "Aufenthalts-Attribution"
    },
    "toast": {
      "copySuccess": "In die Zwischenablage kopiert",
      "success": "Aktion war erfolgreich",
      "unexpectedError": "Ein unerwarteter Fehler ist aufgetreten"
    },
    "toasts": {
      "loading": {
        "description": "Etwas Geduld bitte, wir sind fast fertig!",
        "title": "Das dauert länger als erwartet."
      }
    },
    "uiActions": {
      "apply": "Anwenden",
      "back": "Zurück",
      "clear": "Zurücksetzen",
      "close": "Schließen",
      "confirm": "Bestätigen",
      "delete": "Löschen",
      "edit": "Bearbeiten",
      "export": "Exportieren",
      "info": "Informationen",
      "menu": "Menü",
      "more": "Mehr",
      "reset": "Zurücksetzen",
      "save": "Speichern",
      "saveAndPublish": "Speichern und veröffentlichen",
      "search": "Suchen"
    },
    "uiApplicationNavigationDrawer": {
      "accountSwitch": {
        "empty": "Keine Organisation gefunden",
        "organizationSettings": "Organisationseinstellungen",
        "searchPlaceholder": "Suchen"
      },
      "appsList": {
        "goToApps": "zu den Apps",
        "myApps": "Meine Apps",
        "planNames": {
          "10": {
            "starter_yearly": "Standard"
          },
          "12": {
            "professional_yearly": "Segmentation",
            "starter_yearly": "Customer Data Platform"
          },
          "50": {
            "enterprise_yearly": "MARKETING CLOUD",
            "professional_yearly": "AUTOMATION CLOUD",
            "starter_yearly": "LEAD CLOUD"
          },
          "51": {
            "professional_yearly": "Attribution",
            "starter_yearly": "Tracking"
          },
          "default": {
            "enterprise_monthly": "Enterprise",
            "enterprise_yearly": "Enterprise",
            "professional_monthly": "Professional",
            "professional_yearly": "Professional",
            "standard": "Standard",
            "starter_monthly": "Starter",
            "starter_yearly": "Starter"
          }
        },
        "showMoreApps": "Weitere Apps entdecken"
      }
    },
    "uiCategoryCard": {
      "contentCountError": "Diese Kategorie kann nicht gelöscht werden, da sie noch Inhalte enthält.",
      "titleError": "Achtung!"
    },
    "uiCollapsibleTable": {
      "collapsibleRowDescription": "{count, plural, =1 {# Unterkategorie} other {# Unterkategorien}}"
    },
    "uiColorComposition": {
      "dialog": {
        "preview": "Vorschau",
        "save": "Speichern"
      }
    },
    "uiContentCount": {
      "count": "{count, plural, =1 {# Eintrag} other {# Einträge}}",
      "filterCount": "{count, plural, =1 {# Eintrag} other {# Einträge}} gefunden",
      "pages": "Seite <span class=\"primary ph05\">{page}</span> / <span class=\"pl05\">{total}</span>"
    },
    "uiContentInfo": {
      "showLess": "Weniger anzeigen",
      "showMore": "Mehr anzeigen"
    },
    "uiDateFilter": {
      "label": "Betrachtungszeitraum"
    },
    "uiDatePicker": {
      "filters": {
        "custom_date": "Benutzerdefiniert",
        "last_month": "Letzter Monat",
        "last_thirty_days": "Letzte 30 Tage",
        "last_week": "Letzte Woche",
        "this_month": "Dieser Monat",
        "this_week": "Diese Woche"
      },
      "weekDaysShort": {
        "Fri": "Fr",
        "Mon": "Mo",
        "Sat": "Sa",
        "Sun": "So",
        "Thu": "Do",
        "Tue": "Di",
        "Wed": "Mi"
      }
    },
    "uiDatetimePicker": {
      "date": "Datum",
      "endDate": "Enddatum",
      "endTime": "Enduhrzeit",
      "error": {
        "beforeToday": "Vergangenes Datum",
        "false": "Ungültiges Datum"
      },
      "startDate": "Startdatum",
      "startTime": "Startuhrzeit",
      "time": "Uhrzeit"
    },
    "uiDiscardChanges": {
      "discardAction": "Verwerfen",
      "message": "Sollen ungespeicherte Änderungen verworfen werden?",
      "title": "Achtung!"
    },
    "uiEditor": {
      "actions": {
        "blockquote": "Zitat",
        "bold": "Fett",
        "bulletList": "Aufzählungsliste",
        "code": "Code",
        "codeBlock": "Code",
        "headings": {
          "h1": "Überschrift 1",
          "h2": "Überschrift 2",
          "h3": "Überschrift 3",
          "h4": "Überschrift 4",
          "p": "Normal"
        },
        "headingsShort": {
          "h1": "H1",
          "h2": "H2",
          "h3": "H3",
          "h4": "H4",
          "p": "P"
        },
        "horizontalRule": "Trennlinie",
        "italic": "Kursiv",
        "link": "Link",
        "numberedList": "Nummerierte Liste",
        "orderedList": "Nummerierte Liste",
        "placeholder": "Platzhalter",
        "quote": "Zitat",
        "redo": "Wiederherstellen",
        "spacer": "Trennlinie",
        "underline": "Unterstrichen",
        "undo": "Rückgängig",
        "unformat": "Formatierung entfernen"
      },
      "colors": {
        "accent": "Akzentfarbe",
        "ambient": "Umgebungsfarbe",
        "default": "Keine Farbe",
        "main": "Hauptfarbe"
      },
      "urlDialog": {
        "openInNewTab": "In neuem Tab öffnen",
        "save": "Speichern",
        "text": "Text",
        "type": {
          "name": "Link-Typ",
          "options": {
            "email": "E-Mail",
            "mailto:": "E-Mail",
            "tel": "Telefon",
            "tel:": "Telefon",
            "weblink": "Weblink"
          }
        },
        "url": "URL",
        "urlNotValid": {
          "email": "Diese E-Mail-Adresse ist nicht gültig",
          "mailto:": "Diese E-Mail-Adresse ist nicht gültig",
          "tel": "Diese Telefonnummer ist nicht gültig",
          "tel:": "Diese Telefonnummer ist nicht gültig",
          "weblink": "Diese URL ist nicht gültig"
        }
      }
    },
    "uiFilterInputs": {
      "buttonText": "Filtern",
      "daterange": {
        "end": "bis",
        "start": "von"
      }
    },
    "uiFormControl": {
      "recommendedLengthWarning": "Wert überschreitet die empfohlene Länge von {recommendedLength} Zeichen"
    },
    "uiFormDialog": {
      "save": "Speichern"
    },
    "uiFormMessage": {
      "characters": "{charCount} Zeichen"
    },
    "uiListView": {
      "bulkDelete": "{count, plural, =1 {Ein Element} other {# Elemente}} gelöscht",
      "common": {
        "bulkDelete": {
          "action": "Alle löschen",
          "description": "Die ausgewählten Inhalte werden dadurch unwiederruflich gelöscht und können nicht mehr wiederhergestellt werden.<br/>Sollen die ausgewählten Inhalte wirklich gelöscht werden?"
        },
        "deleteContent": {
          "description": "Der Inhalt wird dadurch unwiederruflich gelöscht und kann nicht mehr wiederhergestellt werden.<br/>Soll <span class=\"font-medium\">\"{name}\"</span> wirklich gelöscht werden?",
          "title": "Achtung!"
        }
      },
      "conflict_errors": {
        "person": {
          "description": "Diese Person kann nicht gelöscht werden, da es von anderen Inhalten verwendet wird. Lösen Sie die Abhängigkeiten bevor Sie diese Person löschen.",
          "title": "Diese Person ist in Verwendung"
        }
      },
      "delete": "Löschen",
      "empty": "Es gibt noch keine Inhalte.<br/>Klicken Sie auf den untenstehenden Button um einen Inhalt zu erstellen.",
      "error": "Ein unerwarteter Fehler ist aufgetreten.",
      "errors": {
        "rate_in_use": "Diese Rate kann nicht gelöscht werden, da Sie in einem Preiszeitraum verwendet wird.",
        "title": "Achtung!",
        "used_in_post": "Diese Person kann nicht gelöscht werden, da sie in einem Beitrag verwendet wird.",
        "vouchers_left": "Dieser Inhalt kann nicht gelöscht werden, da er in einem Gutschein verwendet wird."
      },
      "noMatch": "Ihre Filterung ergab keine Treffer.",
      "properties": {
        "published": "öffentlich",
        "unpublished": "nicht öffentlich"
      }
    },
    "uiMonthDayPicker": {
      "day": "Tag",
      "month": "Monat"
    },
    "uiNavigationBarFilterabel": {
      "dynamicFilters": {
        "buttonText": "Weitere Filter",
        "error": {
          "retryButtonText": "Es ist ein Fehler aufgetreten! Jetzt neu versuchen"
        },
        "navigationDrawer": {
          "title": "Weitere Filter"
        }
      },
      "searchInputDefaultPlaceholder": "Durchsuchen"
    },
    "uiOnboarding": {
      "actions": {
        "back": "Zurück",
        "endOnboarding": "Jetzt loslegen",
        "next": "Weiter",
        "skip": "Überspringen"
      }
    },
    "uiPublishResource": {
      "description": "Wählen Sie hier aus welche Sprachen Sie veröffentlichen möchten. Zudem können Sie den Rahmen der Veröffentlichung zeitlich einschränken.",
      "descriptionNoLang": "Wählen Sie den Zeitpunkt für die Veröffentlichung aus.",
      "errors": {
        "after": "Muss nach dem Startdatum sein",
        "before": "Muss vor dem Enddatum sein",
        "required": "Darf nicht leer sein"
      },
      "languages": "Sprachen",
      "limitTime": "Veröffentlichungszeitraum einschränken",
      "save": "Speichern",
      "settings": "Einstellungen",
      "title": "Veröffentlichen"
    },
    "uiRangeSlider": {
      "errorMessage": "Der Wert muss eine positive Ganzzahl sein",
      "max": "max",
      "min": "min"
    },
    "uiTable": {
      "empty": "Keine Daten vorhanden",
      "error": "Ein Fehler ist aufgetreten",
      "retry": "Nochmal versuchen",
      "showLess": "Weniger anzeigen",
      "showMore": "Mehr anzeigen"
    },
    "uiTags": {
      "error": "Dieser Wert wurde bereits hinzugefügt",
      "interests": {
        "interests": "Interessen",
        "travelMotivation": "Reisemotive",
        "travelTime": "Reisezeiten"
      },
      "placeholder": "Tag hinzufügen",
      "tags": "Tags"
    },
    "uiTimepicker": {
      "errorMessage": "Ungültige Zeitangabe",
      "timeUnit": "Uhr"
    },
    "uiToast": {
      "success": "Aktion erfolgreich durchgeführt",
      "unexpectedError": "Ein unerwarteter Fehler ist aufgetreten"
    }
  };
});