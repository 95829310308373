define("additive-mi/mirage/factories/report-configuration", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    useReservationData: () => _faker.default.random.boolean(),
    calculateConversionValuesAsRevenue: () => _faker.default.random.boolean(),
    calculateNewsletterOpenings: () => _faker.default.random.boolean(),
    calculateRoi: () => _faker.default.random.boolean(),
    cancellationsAsReservations: () => _faker.default.random.boolean(),
    showKpiInfo: () => _faker.default.random.boolean(),
    showDescriptionSlide: () => _faker.default.random.boolean(),
    showCharts: () => _faker.default.random.boolean(),
    showHistoricalDataCharts: () => _faker.default.random.boolean(),
    optionsAsReservations: () => _faker.default.random.boolean(),
    isMonthlyComparisonTableAllowed: () => _faker.default.random.boolean(),
    sessionBasis: () => ({
      value: _faker.default.random.arrayElement(['cookies', 'cookieless']),
      allowedValues: [{
        value: 'cookies',
        label: 'Cookies'
      }, {
        value: 'cookieless',
        label: 'Cookieless'
      }]
    }),
    afterCreate(setting, server) {
      const settings = server.schema.settings.first();
      setting.update({
        useEmpiricalDataToCalculateRevenue: settings.useEmpiricalDataToCalculateRevenue,
        calculateConversionValuesAsRevenue: settings.calculateConversionValuesAsRevenue,
        averageReservationRevenue: settings.averageReservationRevenue,
        conversionRateRequestsToReservations: settings.conversionRateRequestsToReservations,
        includeHosts: settings.includeHosts,
        showNewsletterMarketingSlide: setting.calculateNewsletterOpenings ? _faker.default.random.boolean() : false
      });
    }
  });
});