define("additive-mi/controllers/instance/reports/custom", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-concurrency", "date-fns/format", "@ember/template"], function (_exports, _controller, _object, _service, _tracking, _emberConcurrency, _format, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceReportsCustomController = _exports.default = (_dec = (0, _emberConcurrency.task)(function* () {
    try {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      if (this.newReportName === null || this.newReportName.trim() === '') {
        this.errorMessages = Object.assign(this.errorMessages, {
          nameError: this.intl.t('reports.create.error.nameRequired')
        });
        return;
      }
      if (this.newReportEnd.year === currentYear && parseInt(this.newReportEnd.month) >= currentMonth) {
        this.errorMessages = Object.assign(this.errorMessages, {
          dateError: this.intl.t('reports.create.error.endInFuture')
        });
        return;
      }
      if (this.newReportEnd.year < this.newReportStart.year || this.newReportEnd.year === this.newReportStart.year && parseInt(this.newReportEnd.month) < parseInt(this.newReportStart.month)) {
        this.errorMessages = Object.assign(this.errorMessages, {
          dateError: this.intl.t('reports.create.error.endBeforeStart')
        });
        return;
      }
      const report = this.store.createRecord('custom-report', {
        title: this.newReportName,
        reportStart: {
          month: this.newReportStart.month,
          year: this.newReportStart.year
        },
        reportEnd: {
          month: this.newReportEnd.month,
          year: this.newReportEnd.year
        },
        isCustom: true,
        settings: {}
      });
      yield report.save();
      this.closeAndResetReportDialog();
      this.uiFilter.reset('custom-report');
      this.router.transitionTo('instance.reports.custom.detail', report.id);
    } catch (e) {
      const error = e?.errors?.length ? e.errors[0] : null;
      if (error?.status === 422) {
        this.errorMessageList = error?.messages?.map(msg => (0, _template.htmlSafe)(msg)) || [];
        this.isSaveErrorOpen = true;
        return;
      }
      this.closeAndResetReportDialog();
      this.uiToast.showToast({
        title: this.intl.t('global.toast.error.savedChanges'),
        type: 'error'
      });
    }
  }), _class = class InstanceReportsCustomController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "uiLocale", _descriptor3, this);
      _initializerDefineProperty(this, "uiToast", _descriptor4, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor5, this);
      _initializerDefineProperty(this, "uiFilter", _descriptor6, this);
      _initializerDefineProperty(this, "intl", _descriptor7, this);
      _initializerDefineProperty(this, "newReportName", _descriptor8, this);
      _initializerDefineProperty(this, "newReportStart", _descriptor9, this);
      _initializerDefineProperty(this, "newReportEnd", _descriptor10, this);
      _initializerDefineProperty(this, "isAddReportDialogOpen", _descriptor11, this);
      _initializerDefineProperty(this, "errorMessages", _descriptor12, this);
      _initializerDefineProperty(this, "isSaveErrorOpen", _descriptor13, this);
      _initializerDefineProperty(this, "errorMessageList", _descriptor14, this);
      _initializerDefineProperty(this, "saveNewReport", _descriptor15, this);
    }
    get isPreviewOpen() {
      return this.router.currentRouteName === 'instance.reports.custom.detail.index';
    }
    get _monthOptions() {
      return [...Array(12).keys()].map(month => ({
        label: (0, _format.default)(new Date(0, month), 'LLLL', {
          locale: this.uiLocale.dateFnsLocale
        }),
        value: month + 1
      }));
    }
    get _yearOptions() {
      const currentYear = new Date().getFullYear();
      const startYear = currentYear - 10;
      return [...Array(11).keys()].map(year => ({
        label: `${startYear + year}`,
        value: startYear + year
      }));
    }
    setDate(property, value) {
      (0, _object.set)(this, property, value);
      this.errorMessages = {};
    }
    openPreview(report) {
      this.router.transitionTo('instance.reports.custom.detail', report.id);
    }
    discardAddReport() {
      this.uiDialog.showDiscardChangesConfirm(() => {
        this.closeAndResetReportDialog();
      });
    }
    closeAndResetReportDialog() {
      this.newReportName = null;
      this.errorMessages = {};
      this.newReportStart = {
        month: `${new Date().getMonth()}`,
        year: new Date().getFullYear()
      };
      this.newReportEnd = {
        month: `${new Date().getMonth()}`,
        year: new Date().getFullYear()
      };
      this.isAddReportDialogOpen = false;
    }
    onChangeReportName() {
      this.errorMessages = Object.assign(this.errorMessages, {
        nameError: null
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uiLocale", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiFilter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "newReportName", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "newReportStart", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        month: new Date().getMonth(),
        year: new Date().getFullYear()
      };
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "newReportEnd", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        month: new Date().getMonth(),
        year: new Date().getFullYear()
      };
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isAddReportDialogOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "errorMessages", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "isSaveErrorOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "errorMessageList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "saveNewReport", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setDate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openPreview", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openPreview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "discardAddReport", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "discardAddReport"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeAndResetReportDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeAndResetReportDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeReportName", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeReportName"), _class.prototype), _class);
});