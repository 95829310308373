define("additive-mi/components/ami-insights/base", ["exports", "additive-mi/config/environment", "@additive-apps/ui/components/ui-property-watcher", "@ember/object", "ember-concurrency", "@ember/service", "@additive-apps/ui/utils/query-param-util"], function (_exports, _environment, _uiPropertyWatcher, _object, _emberConcurrency, _service, _queryParamUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * This base component fetches the data.
   * This component is not working as it is, you have to extend it and
   * be sure to define/overwrite the `type` property which will be used
   * for the fetch url.
   *
   * It has a `_data` object which is the apis response json.
   *
   * @class ami-insights/base
   */
  var _default = _exports.default = _uiPropertyWatcher.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    /**
     * the date range object
     *
     * @property filters
     * @type {Object}
     */
    filters: null,
    /**
     * the current organization slug
     *
     * @property organizationSlug
     * @type {String}
     * @default null
     */
    organizationSlug: null,
    /**
     * the insight type
     *
     * @property type
     * @type {String}
     * @default null
     */
    type: null,
    /**
     * Apis response
     *
     * @property _data
     * @type {Object}
     * @default null
     */
    _data: null,
    /**
     * AbortController for the current fetch
     *
     * @property _abortController
     * @type {AbortController}
     * @default null
     * @private
     */
    _abortController: null,
    /**
     * Whether an error occurred
     *
     * @property _isError
     * @type {Boolean}
     * @default false
     */
    _isError: false,
    init() {
      this._super(...arguments);
      (0, _object.setProperties)(this, {
        // property watcher properties
        _watchedProperties: ['filters', 'organizationSlug'],
        _onPropertyChange: () => this._loadData.perform()
      });
    },
    /**
     * fetches the data
     *
     * @type {Task}
     * @function _loadData
     * @private
     */
    _loadData: (0, _emberConcurrency.task)(function* () {
      (0, _object.set)(this, '_isError', false);
      this._abortController && this._abortController.abort();
      const tasks = [];
      const baseUrl = _environment.default.APP.apiBaseHost;
      const urlPart = this.type;
      const _filters = {
        start: this.filters.start,
        end: this.filters.end,
        hosts: this.filters.hosts
      };
      const qps = (0, _queryParamUtil.getQueryParams)(_filters);
      const url = `${baseUrl}/${this.organizationSlug}/${urlPart}${qps}`;
      try {
        this._abortController = new AbortController();
        tasks.push(this.authenticatedFetch.fetch(url, {
          signal: this._abortController.signal
        }));
        tasks.push((0, _emberConcurrency.timeout)(250));
        const [response] = yield (0, _emberConcurrency.all)(tasks);
        if (!response || response.status >= 400) {
          throw new Error();
        }
        const json = yield response.json();
        (0, _object.set)(this, '_data', json);
      } catch (error) {
        (0, _object.set)(this, '_isError', true);
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }).restartable().on('init'),
    // eslint-disable-next-line ember/no-component-lifecycle-hooks
    willDestroyElement() {
      this._super(...arguments);
      this._abortController && this._abortController.abort();
    }
  });
});