define("additive-mi/routes/instance", ["exports", "additive-mi/config/environment", "@ember/routing/route", "@ember/service", "ember-concurrency", "@additive-apps/auth/mixins/auth-route-mixin", "additive-mi/utils/helpers", "@userback/widget", "additive-mi/utils/constants"], function (_exports, _environment, _route, _service, _emberConcurrency, _authRouteMixin, _helpers, _widget, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9; // eslint-disable-next-line ember/no-mixins
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceRoute = _exports.default = (_dec = (0, _emberConcurrency.task)(function* (params) {
    try {
      if (this.currentUser?.currentOrganization?.id && this.currentUser.currentOrganization.id !== params.instance_id) {
        this.uiAppSettings.reset();
      }
      const organization = yield this.store.findRecord('organization', params.instance_id);
      this.currentUser.set('currentOrganization', organization);
      let tasks = [];
      tasks.push(this.currentUser.loadUser());
      tasks.push(this.uiAppSettings.loadLanguages());
      yield (0, _emberConcurrency.all)(tasks);
      yield this.uiLocale.setLocale(this.currentUser?.user?.language || this.currentUser?.currentOrganization?.language || 'de');
      yield (0, _emberConcurrency.waitForProperty)(this.uiAppSettings, 'modules');
      const hasReportsModule = !!this.uiAppSettings?.modules?.find(element => element === 'campaign-report');
      if (hasReportsModule) {
        this.currentUser?.currentOrganization?.set('hasReports', true);
      }
      if (this.currentUser.isAdditiveUser || this.currentUser.isPartnerUser) {
        (0, _widget.default)(this.currentUser.isAdditiveUser ? _environment.default.APP.userbackAccessToken : _environment.default.APP.userbackAccessTokenPartner, {
          email: this.currentUser.user.email,
          name: this.currentUser.user.fullName,
          categories: 'MI'
        });
      }
      if (typeof Sprig === 'function' && this.currentUser?.user) {
        /* eslint-disable */
        Sprig('setUserId', this.currentUser.user.id);
        Sprig('setEmail', this.currentUser.user.email);
        /* eslint-enable */
      }
      return organization;
    } catch (e) {
      this.uiDialog.showError();
    }
  }), _class = class InstanceRoute extends _route.default.extend(_authRouteMixin.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "amiLocalStorage", _descriptor, this);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor2, this);
      _initializerDefineProperty(this, "currentUser", _descriptor3, this);
      _initializerDefineProperty(this, "session", _descriptor4, this);
      _initializerDefineProperty(this, "store", _descriptor5, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor6, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor7, this);
      _initializerDefineProperty(this, "uiLocale", _descriptor8, this);
      /**
       * fetches the organization data
       *
       * @function fetchOrganizationData
       * @type {Task}
       * @param {Object} params the route params
       */
      _initializerDefineProperty(this, "fetchOrganizationData", _descriptor9, this);
    }
    model(params) {
      return this.fetchOrganizationData.perform(params);
    }
    setupController(controller) {
      super.setupController(...arguments);
      const defaultDateFilters = (0, _helpers.getDefaultFilterDates)();
      if (!localStorage.getItem(_constants.LOCAL_STORAGE_FILTER_KEY)) localStorage.setItem(_constants.LOCAL_STORAGE_FILTER_KEY, JSON.stringify({}));

      // set general filters
      const localStorageDefault = Object.assign({}, defaultDateFilters, (0, _helpers.getDefaultFilters)(), this.amiLocalStorage.getStoredValue(_constants.LOCAL_STORAGE_FILTER_KEY));

      // get date filter from query params
      const urlParams = new URLSearchParams(window.location.search);
      const dateFilter = urlParams.get('dateFilter');
      const start = urlParams.get('start');
      const end = urlParams.get('end');

      // set local storage entry to query param
      if (dateFilter) localStorageDefault.dateFilter = dateFilter;
      if (end && start) {
        localStorageDefault.start = start;
        localStorageDefault.end = end;
      }
      if ((!localStorageDefault.start || !localStorageDefault.end) && !dateFilter) {
        localStorageDefault.dateFilter = 'last_month';
      }
      this.amiLocalStorage.setValue(_constants.LOCAL_STORAGE_FILTER_KEY, localStorageDefault, false);

      // set organization-specific filters
      const localStorageOrganizationDefault = Object.assign({}, (0, _helpers.getDefaultOrganizationFilters)(), this.amiLocalStorage.getStoredValue(`${_constants.LOCAL_STORAGE_FILTER_KEY}-${controller.model.id}`));
      this.amiLocalStorage.setValue(`${_constants.LOCAL_STORAGE_FILTER_KEY}-${controller.model.id}`, localStorageOrganizationDefault, false);
      this.amiLocalStorage.setLocalStorageKeys([_constants.LOCAL_STORAGE_FILTER_KEY, `${_constants.LOCAL_STORAGE_FILTER_KEY}-${controller.model.id}`]);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "amiLocalStorage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "uiLocale", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "fetchOrganizationData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});