define("additive-mi/templates/instance/reports/automated", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "2B6wcLDw",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"notices\"]]],null]],null],null,[[[1,\"  \"],[8,[39,2],null,[[\"@classNames\",\"@title\",\"@icon\",\"@isWarning\",\"@isError\",\"@buttonText\",\"@onButtonClick\"],[\"mb3\",[30,1,[\"message\"]],\"info-state\",[28,[37,3],[[30,1,[\"severity\"]],\"warning\"],null],[28,[37,3],[[30,1,[\"severity\"]],\"error\"],null],[30,1,[\"buttonText\"]],[28,[37,4],[[30,0,[\"transitionToSettings\"]],[30,1,[\"action\"]]],null]]],null],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[8,[39,5],null,[[\"@openPreview\",\"@isPreviewOpen\"],[[30,0,[\"openPreview\"]],[30,0,[\"isPreviewOpen\"]]]],null]],[\"notice\"],false,[\"each\",\"-track-array\",\"ui-inline-notification\",\"eq\",\"fn\",\"ami-reports/list\"]]",
    "moduleName": "additive-mi/templates/instance/reports/automated.hbs",
    "isStrictMode": false
  });
});